<template>
    <div class="qingwu">
        <el-form  label-width="160px" ref="info" :model="info">
            <el-form-item label-width="130px" label="新客专享原价" prop="F_VR_XINKZXYJ" :rules="[{required:true,message:'新客专享原价不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_XINKZXYJ"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="新客专享现价" prop="F_VR_XINKZXXJ" :rules="[{required:true,message:'新客专享现价不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_XINKZXXJ"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="年费特惠原价" prop="F_VR_NIANFTHYJ" :rules="[{required:true,message:'年费特惠原价不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_NIANFTHYJ"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="年费特惠现价" prop="F_VR_NIANFTHXJ" :rules="[{required:true,message:'年费特惠现价不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_NIANFTHXJ"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="首充活动原价" prop="F_VR_SHOUCHDYJ" :rules="[{required:true,message:'首充活动原价不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_SHOUCHDYJ"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="首充活动现价" prop="F_VR_SHOUCHDXJ" :rules="[{required:true,message:'首充活动现价不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_SHOUCHDXJ"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="开启自动续费首月费用" prop="F_VR_KAIQMYFXSYF" :rules="[{required:true,message:'开启自动续费首月费用不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_KAIQMYFXSYF"><template slot="append">¥</template></el-input></el-form-item>
            <el-form-item label-width="130px" label="第二个月开始每月费用" prop="F_VR_DIEGYMYFY" :rules="[{required:true,message:'第二个月开始每月费用不能为空',trigger: 'blur' }]"><el-input type="number" placeholder="请输入内容" v-model="info.F_VR_DIEGYMYFY"><template slot="append">¥</template></el-input></el-form-item>

            <el-form-item label-width="160px" label="新客专享说明" prop="F_VR_XINKZX" :rules="[{required:true,message:'内容不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.F_VR_XINKZX"></el-input></el-form-item>
            <el-form-item label-width="160px" label="年费特惠说明" prop="F_VR_NIANFTH" :rules="[{required:true,message:'内容不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.F_VR_NIANFTH"></el-input></el-form-item>
            <el-form-item label-width="160px" label="首充活动说明" prop="F_VR_SHOUCHD" :rules="[{required:true,message:'内容不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.F_VR_SHOUCHD"></el-input></el-form-item>


            <el-form-item label-width="160px" label="新客专享产品ID" prop="F_IN_XINKZXCPID" :rules="[{required:true,message:'内容不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.F_IN_XINKZXCPID"></el-input></el-form-item>
            <el-form-item label-width="160px" label="年费特惠产品ID" prop="F_IN_NIANFTHCPID" :rules="[{required:true,message:'内容不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.F_IN_NIANFTHCPID"></el-input></el-form-item>
            <el-form-item label-width="160px" label="首充活动产品ID" prop="F_IN_SHOUCHDCPID" :rules="[{required:true,message:'内容不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.F_IN_SHOUCHDCPID"></el-input></el-form-item>

            <!--<el-form-item label-width="160px" label="APPSECRET" prop="appsecret" :rules="[{required:true,message:'APPSECRET不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.appsecret"></el-input></el-form-item>
            <el-form-item label-width="160px" label="MCH_ID" prop="mchid" :rules="[{required:true,message:'MCH_ID不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.mchid"></el-input></el-form-item>
            <el-form-item label-width="160px" label="KEY" prop="key" :rules="[{required:true,message:'KEY不能为空',trigger: 'blur' }]"><el-input placeholder="请输入内容" v-model="info.key"></el-input></el-form-item>
            <el-form-item label-width="160px" label="notify_url" prop="notify_url" :rules="[{required:true,message:'通知地址不能为空',trigger: 'blur' }]"><el-input placeholder="请输入通知地址" v-model="info.notify_url"></el-input></el-form-item>
           -->
            <el-form-item label-width="160px" label="备注："><el-tag type="info">VIP会员费用设置</el-tag></el-form-item>
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.$post(this.$api.wxPayJsApiConfig,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_wxpayjsapi_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_wxpayjsapi_config(){
            this.$get(this.$api.wxPayJsApiConfig).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                }
            });
        },
    },
    created() {
        this.get_wxpayjsapi_config();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>