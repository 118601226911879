<template>
    <div class="qingwu">
        <el-form  label-width="100px" ref="info" :model="info">
            <el-form-item label="LOGO" prop="logo">
                <el-upload class="avatar-uploader" :action="$api.logoUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                    <img v-if="info.logo" :src="info.logo" class="avatar-upload">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item  label="app名称" prop="appmc"
                          :rules="[{required:true,message:'app名称不能为空',trigger: 'blur' }]">
                <el-input placeholder="请输入内容" v-model="info.appmc"></el-input>
            </el-form-item>
            <el-form-item  label="备注："><el-tag type="info">关于我们</el-tag></el-form-item>
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{

          },
          upload_headers:{},
          douyewm:''
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.$post(this.$api.douyinConfig,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_alipaypc_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_alipaypc_config(){
            this.$get(this.$api.douyinConfig).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                }
            });
        },
        handleAvatarSuccess(res) {
            this.info.logo = res.data;
            this.$forceUpdate();
        },
    },
    created() {
        this.get_alipaypc_config();
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>