<template>
    <div class="qingwu">
        <el-form label-width="100px" ref="info" :model="info">
            <el-form-item label-width="160px" label="邀请好友" prop="yaoqhy"
                          :rules="[{required:true,message:'邀请好友不能为空',trigger: 'blur' }]">
                <el-input placeholder="请输入内容" v-model="info.yaoqhy"></el-input>
            </el-form-item>
            <el-form-item label-width="160px" label="签到" prop="qiand"
                          :rules="[{required:true,message:'签到不能为空',trigger: 'blur' }]">
                <el-input placeholder="请输入内容" v-model="info.qiand"></el-input>
            </el-form-item>
            <el-form-item label-width="160px" label="赚取积分" prop="zhuanqjf"
                          :rules="[{required:true,message:'赚取积分不能为空',trigger: 'blur' }]">
                <el-input placeholder="请输入内容" v-model="info.zhuanqjf"></el-input>
            </el-form-item>

            <el-form-item label-width="160px" label="备注：">
                <el-tag type="info">积分配置</el-tag>
            </el-form-item>
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                info: {},
            };
        },
        watch: {},
        computed: {},
        methods: {
            submitForm: function (e) {
                this.$refs[e].validate(res => {
                    if (res) {
                        // Http 请求
                        this.$post(this.$api.wxPayAppConfig, this.info).then(postRes => {
                            if (postRes.code == 200) {
                                this.$message.success("编辑成功");
                                this.get_wxpayapp_config();
                            } else {
                                this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_wxpayapp_config() {
                this.$get(this.$api.wxPayAppConfig).then(res => {
                    if (res.data != null) {
                        this.info = res.data;
                    }
                });
            },
        },
        created() {
            this.get_wxpayapp_config();
        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>

</style>