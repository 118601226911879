<template>
    <div class="qingwu">
        <el-form  label-width="100px" ref="info" :model="info">
            <el-form-item label-width="160px" label="微博链接" prop="weiblj"
                          :rules="[{required:true,message:'微博链接不能为空',trigger: 'blur' }]">
                <el-input maxlength="200" type="textarea" placeholder="请输入内容" v-model="info.weiblj"></el-input>
            </el-form-item>
            <el-form-item label-width="160px" label="备注："><el-tag type="info">微博链接</el-tag></el-form-item>
            <el-form-item label-width="160px">
                <el-button type="primary" @click="submitForm('info')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          info:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        submitForm:function(e){
            this.$refs[e].validate(res=>{
                if(res){
                    // Http 请求
                    this.$post(this.$api.aliPayAppConfig,this.info).then(postRes=>{
                        if(postRes.code == 200){
                            this.$message.success("编辑成功");
                            this.get_alipayapp_config();
                        }else{
                            this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_alipayapp_config(){
            this.$get(this.$api.aliPayAppConfig).then(res=>{
                if(res.data != null){
                    this.info  = res.data;
                }
            });
        },
    },
    created() {
        this.get_alipayapp_config();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>